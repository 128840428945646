.Burger {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
  transition: 200ms ease-out;
  z-index: 101;
}

.Burger > div {
  width: 100%;
  height: 4px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  right: 0;
  top: 0;
  bottom: 0;
  transition: 200ms ease-out;
}

.Burger.Open {
  right: 75%;
}

.Burger.Open > div:nth-child(1) {
  transform: rotate(45deg);
  top: 12px;
}

.Burger.Open > div:nth-child(2) {
  opacity: 0;
  right: 20px;
}

.Burger.Open > div:nth-child(3) {
  bottom: 9px;
  top: auto;
  transform: rotate(-45deg);
}

.Navigation {
  position: fixed;
  top: 0;
  right: 0;
  width: 75%;
  background-color: #333;
  transform: translate(100%);
  transition: 200ms ease-out;
  height: 100vh;
  overflow-y: auto;
  z-index: 100;
}

@media (min-width: 500px) {
  .Burger.Open {
    right: 350px;
  }

  .Navigation {
    width: 350px;
  }
}

.Navigation.Open {
  transform: translate(0);
}

.NavLink {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 10px 20px;
  border-left: 4px solid transparent;
  text-decoration: none;
  transition: 200ms ease-out;
}

.NavLink img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.NavLink.Active {
  background-color: #4d4d4d;
  border-left: 4px solid #edbe16;
}
