.Button {
  background: linear-gradient(45deg, #edbe16 0%, #fdfe96 50%, #edbe16 100%);
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.4);
  appearance: none;
  border: none;
  border-radius: 5px;
  padding: 10px 30px;
  outline: none;
  text-transform: uppercase;
}

.Button:hover {
  background: linear-gradient(45deg, #f7d900 20%, #fdfe96 50%, #edbe16 80%);
}
