body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

.App{
  flex-grow: 1;
}

table,
tr,
td {
  border-collapse: collapse;
  border-color: transparent;
}

table {
  width: 100% !important;
}

main {
  width: calc(100% - 40px);
  margin: 0 auto;
  max-width: 756px;
  padding-bottom: 100px;
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: #07a;
}

.swiper-pagination-bullet {
  border: 2px solid #fff !important;
}

.swiper-pagination-bullet-active {
  background: #edbe16 !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #edbe16 !important;
}

.Button {
  background: linear-gradient(45deg, #edbe16 0%, #fdfe96 50%, #edbe16 100%);
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.4);
  appearance: none;
  border: none;
  border-radius: 5px;
  padding: 10px 30px;
  outline: none;
  text-transform: uppercase;
  margin-top: 20px;
}

.Button:disabled {
  background: #888;
  color: #fff;
}

.react_lightgallery_item {
  width: 33%;
}

@media (max-width: 570px) {
  .react_lightgallery_item {
    width: 50%;
  }
}

@media (max-width: 400px) {
  .react_lightgallery_item {
    width: 100%;
  }
}


.lg-download{
  display: none;
}