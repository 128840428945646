.Footer {
  display: flex;
  justify-content: end;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.Footer a {
  margin-left: 10px;
  text-decoration: none;
  color: #a06900;
}
