.BoardMember {
  border: 1px solid #444;
  border-radius: 5px;
  padding: 10px;
}

.BoardMember:not(:last-child){
  margin-bottom: 20px;
}

.Info {
  display: flex;
}

.Info p, .Info h3{
  margin: 0;
}

.Photo {
  margin-right: 10px;
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid #444;
}
