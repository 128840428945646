.Header {
  position: relative;
  height: 60vw;
  overflow-x: hidden;
  max-height: 500px;
}

.Header.Small{
  min-height: 275px;
  height: 30vh;
}

.Header h2 {
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  width: calc(100% - 40px);
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 13vw;
  color: #f6d32b;
  pointer-events: none;
}

.Header.Small h2{
  font-size: 9vw;
}

.Header h2 span:last-child {
  font-size: 11vw;
}

.Header.Small h2 span:last-child {
  font-size: 8vw;
}

@media (min-width: 1100px) {
  .Header h2 {
    font-size: 140px;
  }

  .Header h2 span:last-child {
    font-size: 120px;
  }

  .Header.Small h2 {
    font-size: 100px;
  }

  .Header.Small h2 span:last-child {
    font-size: 80px;
  }
}

.BackgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.Filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.Logo {
  width: 50px;
  position: relative;
  top: 15px;
  transition: width 100ms ease-out;
  left: 15px;
}

@media (min-width: 600px) {
  .Logo {
    width: 100px;
  }
}

@media (max-width: 600px) {
  .Header.Small h2 {
    font-size: 12vw;
  }

  .Header.Small h2 span:last-child {
    font-size: 10vw;
  }
}

.Backdrop {
  position: fixed;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  transition: 200ms ease-out;
}

.Backdrop.Open {
  opacity: 1;
  pointer-events: all;
}
