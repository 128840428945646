.FlexRow{
    display: flex;
}

.FlexRow > *{
    flex-grow: 1;
}

.FormSubtitle{
    margin: 10px 0 5px 0;
}