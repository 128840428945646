.Tile {
  margin: 20px auto;
  margin-bottom: 0;
  display: flex;
  position: relative;
  border: 1px solid #444;
  border-radius: 4px;
  color: #000;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}

.Tile > * {
  flex-grow: 1;
}

.Date {
  position: absolute;
  top: -12px;
  right: 10px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #fff;
  font-size: 13px;
  font-weight: bold;
  padding: 2px 4px;
}

.Image {
  min-width: 90px;
  min-height: 120px;
  width: 25%;
  background-size: cover;
  background-position: center;
  flex-grow: 0;
  flex-shrink: 0;
  border-right: 1px solid #444;
}

.Title {
  text-shadow: 0px 0px 5px #000;
  font-weight: bold;
  font-size: 15px;
  padding: 9px 10px;
  color: #fff;
  border-bottom: 1px solid #444;
  background: linear-gradient(45deg, #676767 50%, #cecece 70%, #676767 90%);
}

.Text {
  margin: 0;
  padding: 10px;
  font-size: 14px;
}
