.Controls {
  display: flex;
  justify-content: space-between;
  color: #444;
  font-size: 15px;
  margin: 10px 0;
}

.Controls a{
  text-decoration: none;
  color: inherit;
}