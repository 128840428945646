.Controls {
  display: flex;
  justify-content: space-between;
  color: #444;
  font-size: 15px;
  margin: 10px 0;
}

.Controls a {
  text-decoration: none;
  color: inherit;
}

.Thumbnail {
  height: 150px;
  background-position: center;
  background-size: cover;
  margin: 10px;
  cursor: zoom-in;
}

.LightGallery {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  position: relative;
  right: 10px;
}
