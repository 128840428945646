.Container {
    margin-bottom: 15px;
}

.Label{
    display: block;
    margin-bottom: 5px;
}

.Field {
  border: 1px solid #444;
  border-radius: 4px;
  padding: 5px 10px;
  width: calc(100% - 10px);
  box-sizing: border-box;
  outline: none;
  transition: 100ms ease-out;
  font-size: 18px;
}

.Field:focus {
  border-color: #f6d32b !important;
}

.Error {
  border-color: #c00;
}
